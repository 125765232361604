import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CERATE_REQUEST,
    CART_CREATE_SUCCESS,
    CART_CREATE_FAIL,
    CART_DELETE,
    CARTITEM_GET_REQUEST,
    CARTITEM_GET_SUCCESS,
    CARTITEM_GET_FAIL,
    CARTITEM_UPDATE,
    CARTITEM_DELETE,
    CARTITEM_CREATE_REQUEST,
    CARTITEM_CREATE_SUCCESS,
    CARTITEM_CREATE_FAIL,
    CARTITEM_BASIC_CREATE_REQUEST,
    CARTITEM_BASIC_CREATE_SUCCESS,
    CARTITEM_BASIC_CREATE_FAIL,
    CARTITEM_PREMIUM_CREATE_REQUEST,
    CARTITEM_PREMIUM_CREATE_SUCCESS,
    CARTITEM_PREMIUM_CREATE_FAIL,
    CARTITEM_ERROR_MESSAGE_CREAR,
    CARTITEM_BASIC_UPDATE,
    CARTITEM_BASIC_UPDATE_FAIL,
    CARTITEM_PREMIUM_UPDATE,
    CARTITEM_PREMIUM_UPDATE_FAIL
} from '../constants/cartConstans'

//cartを取得or作成をする
export const cartBackendReducer = (state = {cart: {id: ''}}, action) => {
    switch(action.type){
        case CART_CERATE_REQUEST:
            return {loading: true, cart: JSON.parse(localStorage.getItem('cart_id'))}
        case CART_CREATE_SUCCESS:
            return {loading: false, cart: action.payload}
        case CART_CREATE_FAIL:
            return {loading:false, error: action.payload}
        case CART_DELETE:
            return {cart: action.payload}
        default:
            return state
    }
}

//cartitemの操作
export const cartItemReducer = (state = {cartItem:[]}, action) => {
    switch(action.type){
        case CARTITEM_GET_REQUEST:
            return {loading:true, cartItem:[]}
        case CARTITEM_GET_SUCCESS:
            return {loading:false, cartItem:action.payload}
        case CARTITEM_GET_FAIL:
            return {loading:false, error:action.payload, cartItem:[]}
        case CARTITEM_CREATE_REQUEST:
            return {loading: true}
        case CARTITEM_CREATE_SUCCESS:
            return {loading:false, cartItem:action.payload}
        case CARTITEM_CREATE_FAIL:
            return {loading:false, error: action.payload}
        case CARTITEM_UPDATE:
            return {cartItem: action.payload}
        case CARTITEM_DELETE:
            return {cartItem: action.payload}
        case CARTITEM_BASIC_UPDATE:
            return {cartItem: action.payload}
        case CARTITEM_BASIC_UPDATE_FAIL:
            return {error: action.payload}
        case CARTITEM_PREMIUM_UPDATE:
            return {cartItme: action.payload}
        case CARTITEM_PREMIUM_UPDATE_FAIL:
            return {error: action.payload}
        default:
            return state
    }
}

//cartitem create reducer
export const cartItemCreateReducer = (state = {cartItem:{}}, action) => {
    switch(action.type){
        case CARTITEM_CREATE_REQUEST:
            return {loading: true}
        case CARTITEM_CREATE_SUCCESS:
            return {loading:false, cartItem: action.payload}
        case CARTITEM_CREATE_FAIL:
            return {loading:false, error: action.payload}
        case CARTITEM_BASIC_CREATE_REQUEST:
            return {loading:true}
        case CARTITEM_BASIC_CREATE_SUCCESS:
            return {loading:false, message: action.payload}
        case CARTITEM_BASIC_CREATE_FAIL:
            return {loading:false, message: action.payload}
        case CARTITEM_PREMIUM_CREATE_REQUEST:
            return {loading:true}
        case CARTITEM_PREMIUM_CREATE_SUCCESS:
            return {loading:false, message: action.payload}
        case CARTITEM_PREMIUM_CREATE_FAIL:
            return{loading:false, message: action.payload}
        case  CARTITEM_ERROR_MESSAGE_CREAR:
            return {message: action.payload}
        default:
            return state
    }
}



export const cartReducer = (state = {cartItems: []}, action) => {
    const item = action.payload
    const existItem = []
    if(state.cartItems.product){
         existItem.appnd(state.cartItems.find(cart_item => cart_item.product === item.product)) 
    }
    switch(action.type){
        case CART_ADD_ITEM:

            if(existItem){
                return {
                    ...state,
                    cartItems : state.cartItems.map(x => 
                        x.product === existItem.product ? item : x)
                }
            }else{
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                }
            }
        case CART_REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(cart_item => cart_item.product !== action.payload)
            }
            
        default:
            return state
    }
}


